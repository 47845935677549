<template>
<b-card>
  <validation-observer ref="productForm">
  <b-form @submit.prevent="store">
        <b-row>
            <b-col md="6" xl="6" class="mb-1">

                <b-form-group label="Fecha del Menu">
                  <validation-provider
                      #default="{ errors }"
                      name="fecha"
                      rules="required">
                      <b-form-input type="date" v-model="formData.to"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Categorias">
                  <validation-provider
                    #default="{ errors }"
                    name="colegio"
                    rules="required">
                      <v-select
                      v-model="formData.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoriesOptions"
                      label="name"
                      :reduce="(item) => item.id"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Colegio">
                  <validation-provider
                    #default="{ errors }"
                    name="colegio"
                    rules="required">
                      <v-select
                      v-model="formData.school_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="schoolOptions"
                      label="name"
                      :reduce="(item) => item.id"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              <b-form-group label="Titulo">
                  <validation-provider
                      #default="{ errors }"
                      name="nombre"
                      rules="required">
                      <b-form-input v-model="formData.description"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" xl="6" class="mb-1" v-for="(i, x) in formData.days" :key="x">
                <b-form-group :label="days(i.day)">
                   <validation-provider
                      #default="{ errors }"
                      name="nombre"
                      rules="required">
                      <b-form-input v-model="i.name"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="'Precio del almuerzo, del día: ' + days(i.day)">
                   <validation-provider
                      #default="{ errors }"
                      name="precio"
                      rules="required">
                      <b-form-input v-model="i.price"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="'Stock:'">
                   <validation-provider
                      #default="{ errors }"
                      name="stock"
                      rules="required">
                      <b-form-input v-model="i.stock"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-col class="mt-2 d-flex justify-content-end">
            <b-button
                type="submit"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            <feather-icon :icon="'EditIcon'" size="16" class="mr-50"/>
                Actualizar
            </b-button>
        </b-col>
  </b-form>
  </validation-observer>
</b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BMedia, BAvatar, BFormSelect
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
localize('es', es)
import vSelect from 'vue-select'

export default {
  components: {
    BMedia, 
    BAvatar,
    BCard, 
    BRow, 
    BCol, 
    BForm, 
    BFormGroup, 
    BFormInput, 
    BButton, 
    BInputGroup, 
    BInputGroupPrepend, 
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      required,
      school_id: null,
      formData: {
        id: null,
        description: null,
        to: null,
        days: [
          {
            day: 1,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 2,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 3,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 4,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 5,
            name: null,
            price: null,
            qty: null,
            stock: null,
          }
        ]
      },
      schoolOptions: [],
      categoriesOptions: [],
    }
  },
  watch: {
    'formData.school_id': function() {
      this.menuDay()
    }
  },
  methods: {
    async store() {
      await store.dispatch('menu/store', this.formData)
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se creo el Menu exitosamente')
        this.$router.push({ name: 'menu' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    message(icon, variant, text) {
      this.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Respuesta',
        icon: icon,
        variant: variant,
        text: text,
      }})
    },
    async schools(){
      this.schoolOptions = await this.$store.dispatch('schools/index', { schools: true })
    },
    async getCategories() {
      this.categoriesOptions = await store.dispatch('products/index', {categories: true})
    },
    async menuDay(){
      const menu = await store.dispatch('menu/index', this.formData.school_id)
      if(menu) {
        this.formData = menu
      } else {
        this.formData.id = null
        this.formData.description = null
        this.formData.to = null
        this.formData.days = [
          {
            day: 1,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 2,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 3,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 4,
            name: null,
            price: null,
            qty: null,
            stock: null,
          },
          {
            day: 5,
            name: null,
            price: null,
            qty: null,
            stock: null,
          }
        ]
      }
    },
    days(day){
      const arr = [
        'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'
      ]
      return arr[day-1]
    },
  },
  mounted(){
    this.schools()
    this.getCategories()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"productForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.store.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha del Menu"}},[_c('validation-provider',{attrs:{"name":"fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.formData.to),callback:function ($$v) {_vm.$set(_vm.formData, "to", $$v)},expression:"formData.to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Categorias"}},[_c('validation-provider',{attrs:{"name":"colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoriesOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Colegio"}},[_c('validation-provider',{attrs:{"name":"colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.schoolOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.school_id),callback:function ($$v) {_vm.$set(_vm.formData, "school_id", $$v)},expression:"formData.school_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Titulo"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',_vm._l((_vm.formData.days),function(i,x){return _c('b-col',{key:x,staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.days(i.day)}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(i.name),callback:function ($$v) {_vm.$set(i, "name", $$v)},expression:"i.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":'Precio del almuerzo, del día: ' + _vm.days(i.day)}},[_c('validation-provider',{attrs:{"name":"precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(i.price),callback:function ($$v) {_vm.$set(i, "price", $$v)},expression:"i.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":'Stock:'}},[_c('validation-provider',{attrs:{"name":"stock","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(i.stock),callback:function ($$v) {_vm.$set(i, "stock", $$v)},expression:"i.stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1),_c('b-col',{staticClass:"mt-2 d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"type":"submit","variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":'EditIcon',"size":"16"}}),_vm._v(" Actualizar ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }